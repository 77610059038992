import React, { useState } from "react";
import Layout from "../../layout/components/Layout";
import Body from "../../layout/components/Body";
import Middle from "../../layout/components/Col/Middle";
import Footer from "../../layout/components/Footer";
import Header from "../../layout/components/Header";
import MobileMenu from "../../layout/components/MobileMenu";
import i18next from "i18next";
import { FormProvider, useForm } from "react-hook-form";
import { signup } from "../actions/signupActions";
import SignupBenefits from "./SignupBenefits";
import SearchOverlay from "../../search/components/SearchOverlay";
import AddressFields from "../../common/components/AddressFields";
import { AddressesAttributes } from "../../commercelayer/vo/addresses";
import { useLang } from "../../router/hooks/useLang";
import { useCountry } from "../../router/hooks/useCountry";
import CountrySpecific from "../../common/constants/CountrySpecific";
import Country from "../../common/constants/Country";

type SignupData = {
  email: string;
  password: string;
  passwordRepeated: string;
  company: string;
  notes: string;
  line_1: string;
  zip_code: string;
  city: string;
  customerGroup: string;
  phone: string;
  country_code: string;
  state_code: string;
  vat_no: string;
  locale: string;
  contactName: string;
};

const SignupConfig: Record<string, Country[]> = {
  contactName: [Country.USA],
};

const Signup: React.FC = () => {
  const methods = useForm<SignupData>();
  const lang = useLang();
  const country = useCountry();
  const showContactName = SignupConfig.contactName.includes(country);

  const onSubmit = methods.handleSubmit((data: SignupData) => {
    const address: AddressesAttributes = {
      business: true,
      email: data.email,
      company: data.company,
      notes: data.notes,
      line_1: data.line_1,
      zip_code: data.zip_code,
      city: data.city,
      metadata: {
        customerGroup: data.customerGroup,
        contactName: data.contactName,
        locale: lang,
      },
      phone: data.phone,
      country_code: data.country_code,
      state_code: data.state_code,
      vat_no: data.vat_no,
    };

    signup(methods.getValues("email"), methods.getValues("password"), address);
    methods.setValue("password", "");
    methods.setValue("passwordRepeated", "");
  });
  const [pwdInputType, setPwdInputType] = useState("password");
  const onClickEye = function () {
    pwdInputType === "password" ? setPwdInputType("text") : setPwdInputType("password");
  };

  let showUSCstGrp = false;
  if (
    CountrySpecific[country].hasOwnProperty("checkoutCheckAddress") &&
    CountrySpecific[country].checkoutCheckAddress === true
  ) {
    showUSCstGrp = true;
  }

  return (
    <Layout>
      <Header />
      <MobileMenu />
      <SearchOverlay />
      <Body>
        <Middle>
          <div className="grid-wrap grid-wrap--line">
            <div className="grid-1-2">
              <h1 className="col-title">{i18next.t("SIGNUP")}</h1>
              <SignupBenefits />
            </div>
            <div className="grid-1-2">
              <h2 className="col-title">{i18next.t("SIGNUP AS A BUSINESS CUSTOMER")}</h2>
              <FormProvider {...methods}>
                <form className="form register-form" onSubmit={onSubmit}>
                  <AddressFields
                    address={null}
                    emailOptional={false}
                    setValues={false}
                    allowCountrySwitch={true}
                  />

                  <label className="label" htmlFor="customerGroup">
                    {`${i18next.t("CUSTOMERGROUP")} *`}
                  </label>
                  {showUSCstGrp ? (
                    <select
                      className="select"
                      aria-expanded="false"
                      aria-hidden="true"
                      aria-label={`${i18next.t("CUSTOMERGROUP")} *`}
                      {...methods.register("customerGroup", { required: true })}
                    >
                      <option value="DENT">{`${i18next.t("CSTGRP DENTURIST")} `}</option>
                      <option value="DSO">{`${i18next.t("CSTGRP DENTAL SERV ORG")} `}</option>
                      <option value="EMPL">{`${i18next.t("CSTGRP TM EMPLOYEE")} `}</option>
                      <option value="ENDO">{`${i18next.t("CSTGRP ENDODONTIST")} `}</option>
                      <option value="GP">{`${i18next.t("CSTGRP GENERAL PRACTICE")} `}</option>
                      <option value="GP+">{`${i18next.t("CSTGRP GEN PRC+SPEC")} `}</option>
                      <option value="GPP">{`${i18next.t("CSTGRP GEN PRAC+PLACES")} `}</option>
                      <option value="GPPC">{`${i18next.t("CSTGRP GEN PRAC+PLACES COMP")} `}</option>
                      <option value="HOSP">{`${i18next.t("CSTGRP HOSPITAL")} `}</option>
                      <option value="LAB">{`${i18next.t("CSTGRP LAB")} `}</option>
                      <option value="MISC">{`${i18next.t("CSTGRP MISCELLANEOUS")} `}</option>
                      <option value="OMS">{`${i18next.t("CSTGRP ORAL SURGEON")} `}</option>
                      <option value="PEDO">{`${i18next.t("CSTGRP PERODONTIST")} `}</option>
                      <option value="PERI">{`${i18next.t("CSTGRP PERIODONTIST")} `}</option>
                      <option value="PROS">{`${i18next.t("CSTGRP PROSTHODONTIST")} `}</option>
                      <option value="SPEC">{`${i18next.t("CSTGRP MIXED SPECIALISTS")} `}</option>
                      <option value="UNIV">{`${i18next.t("CSTGRP UNIVERSITY")} `}</option>
                    </select>
                  ) : (
                    <select
                      className="select"
                      aria-expanded="false"
                      aria-hidden="true"
                      aria-label={`${i18next.t("CUSTOMERGROUP")} *`}
                      {...methods.register("customerGroup", { required: true })}
                    >
                      <option value="1">{`${i18next.t("CSTGRP DENTIST")} `}</option>
                      <option value="2">{`${i18next.t("CSTGRP DENTAL TECHNICIAN")} `}</option>
                    </select>
                  )}
                  {showContactName && (
                    <>
                      <label className="label " htmlFor="contactName">
                        {`${i18next.t("CONTACT NAME")} *`}
                      </label>
                      <input
                        required
                        type="text"
                        className="input--text"
                        placeholder={i18next.t("CONTACT NAME")}
                        aria-label={i18next.t("CONTACT NAME")}
                        {...methods.register("contactName", { required: true })}
                      />
                    </>
                  )}
                  <label className="label " htmlFor="password">
                    {`${i18next.t("PASSWORD")} *`}
                  </label>
                  <div className="input--password-wrap">
                    <input
                      type={pwdInputType}
                      className="input--password"
                      defaultValue=""
                      placeholder={i18next.t("PASSWORD")}
                      required
                      aria-required="true"
                      aria-label={i18next.t("PASSWORD")}
                      autoComplete="off"
                      {...methods.register("password", {
                        required: true,
                        minLength: {
                          value: 6,
                          message: `${i18next.t("THE PASSWORD MUST BE A MINIMUM OF 6 CHARACTERS")}`,
                        },
                      })}
                    />
                    <span
                      onClick={onClickEye}
                      className="input--password__icon icon-font icon-font__eye"
                    />
                  </div>
                  {methods.formState.errors.password && (
                    <span className="form-error">{methods.formState.errors.password.message}</span>
                  )}

                  <label className="label " htmlFor="passwordRepeated">
                    {`${i18next.t("PLEASE REPEAT PASSWORD")} *`}
                  </label>
                  <div className="input--password-wrap">
                    <input
                      type={pwdInputType}
                      className="input--password"
                      defaultValue=""
                      placeholder={i18next.t("PLEASE REPEAT PASSWORD")}
                      required
                      aria-required="true"
                      aria-label={i18next.t("PLEASE REPEAT PASSWORD")}
                      autoComplete="off"
                      {...methods.register("passwordRepeated", {
                        required: true,
                        validate: value =>
                          value === methods.getValues("password") ||
                          `${i18next.t("THE PASSWORD DOES NOT MATCH THE PASSWORD FIELD")} `,
                      })}
                    />
                    <span
                      onClick={onClickEye}
                      className="input--password__icon icon-font icon-font__eye"
                    />
                  </div>
                  {methods.formState.errors.passwordRepeated && (
                    <span className="form-error">
                      {methods.formState.errors.passwordRepeated.message}
                    </span>
                  )}
                  <button type="submit" className="btn ">
                    {i18next.t("SIGNUP")}
                  </button>
                </form>
              </FormProvider>
            </div>
          </div>
        </Middle>
      </Body>
      <Footer />
    </Layout>
  );
};

export default Signup;
