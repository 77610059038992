import { PaymentMethods } from "../../commercelayer/vo/paymentMethods";
import { ShippingMethods } from "../../commercelayer/vo/shippingMethods";
import i18next from "i18next";

export function getClMethodInfoText(
  option: ShippingMethods | PaymentMethods | null,
): string | null {
  if (option !== null) {
    if (
      i18next.t(`CL_METHOD_${option.attributes.reference}_INFO`) !==
      `CL_METHOD_${option.attributes.reference}_INFO`
    ) {
      return i18next.t(`CL_METHOD_${option.attributes.reference}_INFO`);
    }
  }
  return null;
}
