import React from "react";
import i18next from "i18next";
import { useFormContext } from "react-hook-form";
import { Addresses } from "../../commercelayer/vo/addresses";
import { useCountry } from "../../router/hooks/useCountry";
import { countrySwitch } from "../../layout/actions/layoutActions";
import Country from "../constants/Country";
import AddressConfig from "../constants/AddressConfig";
import { APP_COUNTRIES, APP_TEST_COUNTRIES } from "../../layout/constants/Countries";

interface Props {
  address: Addresses | null;
  emailOptional: boolean;
  setValues: boolean;
  allowCountrySwitch: boolean;
}

const AddressFields: React.FC<Props> = ({
  address,
  emailOptional,
  setValues,
  allowCountrySwitch,
}) => {
  const { register, setValue } = useFormContext();
  const country = useCountry();
  const showVatNo = AddressConfig.vat_no.includes(country);
  const showState = AddressConfig.state_code.includes(country);

  React.useEffect(() => {
    // otherwise form fields would be empty onClickEye:
    if (setValues) {
      // otherwise values of form fields would not be correctly set of otherAddresses (delivery Addresses):
      setValue("company", address?.attributes.company ?? "");
      setValue("notes", address?.attributes.notes ?? "");
      setValue("line_1", address?.attributes.line_1 ?? "");
      setValue("zip_code", address?.attributes.zip_code ?? "");
      setValue("city", address?.attributes.city ?? "");
      setValue("state_code", address?.attributes.state_code ?? "");
      setValue("phone", address?.attributes.phone ?? "");
      setValue("email", address?.attributes.email ?? "");
      setValue("country_code", country ?? "");
      setValue("vat_no", address?.attributes.vat_no ?? "");
    }
  });

  return (
    <>
      <label className="label " htmlFor="company">
        {`${i18next.t("COMPANY NAME")} *`}
      </label>
      <input
        type="text"
        id={address?.id}
        className="input--text"
        placeholder={i18next.t("COMPANY NAME")}
        required
        aria-required="true"
        aria-label={i18next.t("COMPANY NAME")}
        {...register("company", { required: true })}
      />

      <label className="label " htmlFor="notes">
        {`${i18next.t("ADDITIONAL_INFORMATION")} `}
      </label>
      <input
        type="text"
        className="input--text"
        placeholder={i18next.t("ADDITIONAL_INFORMATION")}
        aria-label={i18next.t("ADDITIONAL_INFORMATION")}
        {...register("notes", { required: false })}
      />

      <label className="label " htmlFor="line_1">
        {`${i18next.t("STREET AND HOUSE NUMBER")} *`}
      </label>
      <input
        type="text"
        className="input--text"
        placeholder={i18next.t("STREET AND HOUSE NUMBER")}
        required
        aria-required="true"
        aria-label={i18next.t("STREET AND HOUSE NUMBER")}
        {...register("line_1", { required: true })}
      />
      <label className="label " htmlFor="zip_code">
        {`${i18next.t("ZIP CODE")} *`}
      </label>
      <input
        type="text"
        className="input--text"
        placeholder={i18next.t("ZIP CODE")}
        required
        aria-required="true"
        aria-label={i18next.t("ZIP CODE")}
        {...register("zip_code", { required: true })}
      />
      <label className="label " htmlFor="city">
        {`${i18next.t("PLACE")} *`}
      </label>
      <input
        type="text"
        className="input--text"
        placeholder={i18next.t("PLACE")}
        required
        aria-required="true"
        aria-label={i18next.t("PLACE")}
        {...register("city", { required: true })}
      />
      {showState && (
        <>
          <label className="label " htmlFor="state_code">
            {`${i18next.t("STATE")} *`}
          </label>
          <input
            required
            type="text"
            className="input--text"
            placeholder={i18next.t("STATE")}
            aria-label={i18next.t("STATE")}
            {...register("state_code", { required: true })}
          />
        </>
      )}
      <label className="label " htmlFor="phone">
        {`${i18next.t("PHONE")} *`}
      </label>
      <input
        type="tel"
        className="input--text"
        placeholder={i18next.t("PHONE")}
        required
        aria-required="true"
        aria-label={i18next.t("PHONE")}
        {...register("phone", { required: true })}
      />
      {emailOptional && (
        <>
          <label className="label " htmlFor="email">
            {`${i18next.t("EMAIL")} `}
          </label>
          <input
            type="email"
            className="input--text"
            placeholder={i18next.t("EMAIL")}
            aria-label={i18next.t("EMAIL")}
            {...register("email")}
          />
        </>
      )}
      {!emailOptional && (
        <>
          <label className="label " htmlFor="email">
            {`${i18next.t("EMAIL")} *`}
          </label>
          <input
            type="email"
            className="input--text"
            placeholder={i18next.t("EMAIL")}
            required
            aria-required="true"
            aria-label={i18next.t("EMAIL")}
            {...register("email", { required: true })}
          />
        </>
      )}
      <label className="label " htmlFor="country_code">
        {`${i18next.t("COUNTRY")} `}
      </label>
      {allowCountrySwitch && (
        <select
          className="select"
          value={country}
          aria-expanded="false"
          aria-hidden="true"
          aria-label={i18next.t("COUNTRY")}
          {...register("country_code", {
            onChange: e => {
              countrySwitch(e.target.value).then();
            },
          })}
        >
          {Object.values(Country).map(countryCode => {
            if (APP_COUNTRIES.includes(countryCode) || APP_TEST_COUNTRIES.includes(countryCode)) {
              return (
                <option key={`country_code_option_${countryCode}`} value={countryCode}>
                  {i18next.t("ISO_3166-1 " + countryCode)}
                </option>
              );
            } else {
              return "";
            }
          })}
        </select>
      )}
      {!allowCountrySwitch && (
        <>
          <input
            type="text"
            className="input--text"
            value={`${i18next.t("ISO_3166-1 " + country)}`}
            readOnly={true}
            aria-label={i18next.t("COUNTRY")}
          />
          <input
            type="hidden"
            className="input--text"
            value={country}
            {...register("country_code")}
          />
        </>
      )}
      {showVatNo && (
        <>
          <label className="label " htmlFor="vat_no">
            {`${i18next.t("VAT NO")} *`}
          </label>
          <input
            required
            type="text"
            className="input--text"
            placeholder={i18next.t("VAT NO")}
            aria-label={i18next.t("VAT NO")}
            {...register("vat_no", { required: true })}
          />
        </>
      )}
    </>
  );
};

export default AddressFields;
