import React, { useEffect } from "react";
import Layout from "../../layout/components/Layout";
import Body from "../../layout/components/Body";
import Middle from "../../layout/components/Col/Middle";
import Footer from "../../layout/components/Footer";
import Header from "../../layout/components/Header";
import MobileMenu from "../../layout/components/MobileMenu";
import Left from "../../layout/components/Col/Left";
import Right from "../../layout/components/Col/Right";
import { Link } from "react-router-dom";
import { logout, resetPassword } from "../../login/actions/loginActions";
import CartOverlay from "../../cart/components/CartOverlay";
import NavAccount from "./NavAccount";
import { PATH } from "../../router/constants/Path";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import SearchOverlay from "../../search/components/SearchOverlay";
import {
  deleteCustomerAddress,
  loadCustomerAddresses,
  updateAddresses,
} from "../actions/accountActions";
import { Addresses } from "../../commercelayer/vo/addresses";
import { getMainAddress, getOtherAddresses } from "../scripts/addresses";
import i18next from "i18next";
import Address from "../../common/components/Address";
import { FormProvider, useForm } from "react-hook-form";
import AddressFields from "../../common/components/AddressFields";
import AddressFormAdd from "../../common/components/AddressFormAdd";
import { scrollTop } from "../../layout/scripts/scroll";
import MobileHeaderAccount from "./MobileHeaderAccount";
import InternalInformation from "./InternalInformation";
import { useLangCountry } from "../../router/hooks/useLangCountry";
import { AccountFallback } from "./AccountFallback";
import CountrySpecific from "../../common/constants/CountrySpecific";
import { serviceValidateAddress } from "../../avalara/services/addressValidation";
import { useCountry } from "../../router/hooks/useCountry";
import { toast } from "react-toastify";

type AccountData = {
  email: string;
  company: string;
  notes: string;
  line_1: string;
  zip_code: string;
  city: string;
  phone: string;
  country_code: string;
  state_code: string;
  customerAddressId: string;
  lang: string;
  country: string;
};

const Account: React.FC = () => {
  const country = useCountry();
  const methods = useForm<AccountData>();
  const langCountry = useLangCountry();
  const [showForm, setShowForm] = React.useState(false);
  const [showFormEdit, setShowFormEdit] = React.useState("");

  const accountCustomer = useSelector((state: State) => state.account.customer);
  const accountAddresses = useSelector((state: State) => state.account.addresses);
  useEffect(() => {
    if (accountCustomer?.id) {
      loadCustomerAddresses();
    }
  }, [accountCustomer]);

  if (accountCustomer === null) {
    return <AccountFallback />;
  }

  const mainAddress: Addresses | null = getMainAddress(accountAddresses);
  const otherAddresses: Addresses[] = getOtherAddresses(accountAddresses);

  const onSubmitEdit = methods.handleSubmit((data: AccountData) => {
    let stateCode = "00";
    if (data.state_code) {
      stateCode = data.state_code;
    }

    if (CountrySpecific[country].checkoutCheckAddress) {
      if (!stateCode) {
        return;
      }
      serviceValidateAddress(
        data.line_1,
        data.city,
        data.zip_code,
        stateCode,
        data.country_code,
      ).then(result => {
        if (result.data.valid === true) {
          if (result.data.result.validatedAddresses.length > 0) {
            if (result.data.result.validatedAddresses.length === 1) {
              const validAddr = result.data.result.validatedAddresses[0];
              updateAddresses(showFormEdit, {
                business: true,
                email: data.email,
                company: data.company,
                notes: data.notes,
                line_1: validAddr.line1,
                zip_code: validAddr.postalCode,
                city: validAddr.city,
                phone: data.phone,
                country_code: validAddr.country,
                state_code: validAddr.region,
              });
            } else {
              const message = i18next.t("CONTACT SALES SUPPORT FOR DELIVERY ADDRESS");
              toast.error(message, {
                position: "top-center",
                autoClose: 10000,
              });
            }
          }
        } else {
          setShowFormEdit(showFormEdit);
          const message = result.data.reason;
          toast.error(message, {
            position: "top-center",
            autoClose: 10000,
          });
        }
      });
    } else {
      updateAddresses(showFormEdit, {
        business: true,
        email: data.email,
        company: data.company,
        notes: data.notes,
        line_1: data.line_1,
        zip_code: data.zip_code,
        city: data.city,
        phone: data.phone,
        country_code: data.country_code,
        state_code: stateCode,
      });
    }
  });

  const onClickNewAddress = function (e) {
    setShowForm(true);
  };

  const onClickEditAddress = function (addressId) {
    if (showFormEdit !== addressId) {
      setShowFormEdit(addressId);
    } else {
      setShowFormEdit("");
    }
  };

  const handleClick = () => {
    setShowForm(false);
  };

  const onClickDelete = function (addressId) {
    deleteCustomerAddress(addressId);
  };

  const negotiatedSpecialConditions: number = parseFloat(
    accountCustomer.attributes?.metadata["s_dbt_cnd-kbetr"] ?? 0,
  );

  return (
    <Layout>
      <Header />
      <MobileMenu />
      <SearchOverlay />
      <Body>
        <MobileHeaderAccount activeLi={PATH.ACCOUNT} customerId={accountCustomer.id} />
        <Left>
          <NavAccount activeLi={PATH.ACCOUNT} customerId={accountCustomer.id} />
        </Left>
        <Middle>
          <InternalInformation customer={accountCustomer} />
          <div className="grid-wrap grid-wrap--account">
            {mainAddress && (
              <div className="grid-1-2">
                <h1 className="col-title">{mainAddress.attributes.company}</h1>
                <Address address={mainAddress} allowDelete={false} />

                <dl className="dl-account">
                  <dt className="dl-account__dt">{i18next.t("CUSTOMER ID")}:</dt>
                  <dd className="dl-account__dd">
                    <strong>{accountCustomer.attributes.reference}</strong>
                  </dd>
                  {negotiatedSpecialConditions !== 0 && (
                    <>
                      <dt className="dl-account__dt">
                        {i18next.t("NEGOTIATED SPECIAL CONDITIONS")}:
                      </dt>
                      <dd className="dl-account__dd">
                        <strong>{`${negotiatedSpecialConditions.toFixed(1)} %`}</strong>
                      </dd>
                    </>
                  )}
                </dl>
              </div>
            )}
            <div className="grid-1-2">
              <Link
                to={`${langCountry}`}
                onClick={() => {
                  logout().then();
                  scrollTop();
                }}
                className="btn "
                role="button"
                tabIndex={0}
              >
                <span className="btn__text">{i18next.t("LOGOUT")}</span>
              </Link>
            </div>
          </div>

          {otherAddresses.map((address, index) => {
            if (showFormEdit !== address.id) {
              return (
                <div key={address.id} className="grid-wrap grid-wrap--account">
                  <div className="grid-1-2">
                    <h2 className="col-title">
                      {i18next.t("DELIVERY ADDRESS")} {1 + index}
                    </h2>
                    <Address address={address} allowDelete={true} key={index} />
                  </div>
                  <div className="grid-1-2" />
                  <div className="account-edit-btn-wrap">
                    <div
                      className="icon-btn icon-btn__edit"
                      role="button"
                      tabIndex={0}
                      onClick={e => onClickEditAddress(address.id)}
                    >
                      <span className="visuallyhidden">{i18next.t("SAVE")}</span>
                    </div>

                    <div
                      className="icon-btn icon-btn__delete"
                      role="button"
                      tabIndex={0}
                      onClick={e => onClickDelete(address.id)}
                    >
                      <span
                        className="visuallyhidden"
                        onClick={e => deleteCustomerAddress(address.id)}
                      >
                        {i18next.t("DELETE DELIVERY ADDRESS")}
                      </span>
                    </div>
                  </div>
                </div>
              );
            }
            return (
              <div key={address.id} className="grid-wrap grid-wrap--account">
                <div className="grid-1-2">
                  <h2 className="col-title">
                    {
                      <span>
                        {i18next.t("CHANGE DELIVERY ADDRESS")} {index + 1}
                      </span>
                    }
                  </h2>

                  <FormProvider {...methods}>
                    <form
                      key={index}
                      className="form register-form"
                      onSubmit={() => {
                        onSubmitEdit();
                        onClickEditAddress("");
                      }}
                    >
                      <input
                        type="hidden"
                        className="input--text"
                        defaultValue={otherAddresses[index].id}
                        {...methods.register("customerAddressId", { required: true })}
                      />

                      <AddressFields
                        address={address}
                        emailOptional={true}
                        setValues={true}
                        allowCountrySwitch={false}
                      />
                      <div className="btn-wrap">
                        <button
                          type="button"
                          className="btn transparent"
                          onClick={e => onClickEditAddress("")}
                        >
                          {i18next.t("CANCEL")}
                        </button>

                        <button type="submit" className="btn">
                          {i18next.t("SAVE")}
                        </button>
                      </div>
                    </form>
                  </FormProvider>
                </div>
                <div className="grid-1-2" />
              </div>
            );
          })}

          {showForm && (
            <div className="grid-wrap grid-wrap--account ">
              <div className="grid-1-2">
                <AddressFormAdd onSubmit={() => setShowForm(false)} handleClick={handleClick} />
              </div>
              <div className="grid-1-2" />
            </div>
          )}
          <div className="grid-wrap grid-wrap--account ">
            {!showForm && !showFormEdit ? (
              <div className="grid-1-2">
                <div className="btn transparent">
                  <span className="btn__text" onClick={onClickNewAddress}>
                    {i18next.t("ADD NEW DELIVERY ADDRESS")}
                  </span>
                </div>
              </div>
            ) : (
              <div className="grid-1-2" />
            )}
            <div className="grid-1-2">
              {!showForm && (
                <button
                  type="submit"
                  className="btn transparent"
                  onClick={() => resetPassword(accountCustomer.attributes.email)}
                >
                  {i18next.t("SET NEW PASSWORD")}
                </button>
              )}
            </div>
          </div>
        </Middle>
        <Right removeOnCartClosed={true}>
          <CartOverlay />
        </Right>
      </Body>
      <Footer />
    </Layout>
  );
};
export default Account;
