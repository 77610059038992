import React from "react";
import i18next from "i18next";
import InputRadioCustom from "./InputRadioCustom";
import { PaymentMethods } from "../../commercelayer/vo/paymentMethods";
import { setPaymentMethod } from "../actions/checkoutActions";
import Cart from "../../state/vo/Cart";
import { getClMethodInfoText } from "../scripts/info";
import { getPaymentMethod } from "../scripts/shippingpayment";
import { OrderType } from "../../common/constants/OrderType";

interface Props {
  cart: Cart;
  orderUpdating: boolean;
}

const Payment: React.FC<Props> = ({ cart, orderUpdating }) => {
  const cartPaymentMethod: PaymentMethods | null = getPaymentMethod(cart);

  const onChange = function (e) {
    if (cart.attributes?.metadata.type === OrderType.ORDER) {
      setPaymentMethod(e.target.value);
    }
    // for CST_ORDER it is fixed to INVOICE
  };

  // Fallback orderType is ORDER
  const orderType = cart.attributes?.metadata?.type || OrderType.ORDER;

  return (
    <div className="grid-1-2">
      <h2 className="col-title">{i18next.t("PAYMENT")}</h2>
      <fieldset className="form-fieldset">
        <legend className="visuallyhidden">{i18next.t("PAYMENT")}</legend>
        {orderType === OrderType.ORDER &&
          cart.paymentMethods.map((paymentMethod: PaymentMethods, index: number) => {
            return (
              <InputRadioCustom
                key={index}
                checked={paymentMethod.id === cartPaymentMethod?.id}
                inputId={paymentMethod.id}
                inputValue={paymentMethod.id}
                label={i18next.t(`CL_METHOD_${paymentMethod.attributes.reference}`)}
                info={getClMethodInfoText(paymentMethod)}
                showPrice={false}
                price_amount_float={paymentMethod.attributes.price_amount_float}
                currency_code={paymentMethod.attributes.currency_code}
                onChange={onChange}
                disabled={orderUpdating}
              />
            );
          })}

        {orderType === OrderType.CST_ORDER && cartPaymentMethod && (
          <InputRadioCustom
            checked={true}
            inputId={cartPaymentMethod.id}
            inputValue={cartPaymentMethod.id}
            label={i18next.t(`CL_METHOD_${cartPaymentMethod.attributes.reference}`)}
            info={getClMethodInfoText(cartPaymentMethod)}
            showPrice={false}
            price_amount_float={cartPaymentMethod.attributes.price_amount_float}
            currency_code={cartPaymentMethod.attributes.currency_code}
            onChange={onChange}
            disabled={orderUpdating}
          />
        )}
      </fieldset>
    </div>
  );
};

export default Payment;
