import React from "react";
import { getNumberWithCurrency } from "../../common/scripts/format/number";

interface Props {
  checked: boolean;
  inputId: string;
  inputValue: string;
  label: string;
  info: string | null;
  showPrice: boolean;
  price_amount_float: number;
  currency_code: any; // TODO
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  priceInfo?: string;
  disabled?: boolean;
}

const InputRadioCustom: React.FC<Props> = ({
  checked,
  inputId,
  inputValue,
  label,
  info,
  showPrice,
  price_amount_float,
  currency_code,
  onChange,
  priceInfo,
  disabled = false,
}) => {
  return (
    <div key={inputId} className="input--radio-wrap input--radio-wrap--custom">
      <input
        type="radio"
        className="input--radio"
        id={`input_${inputId}`}
        value={inputValue}
        name={label}
        aria-checked="false"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />

      <label className="label label--radio" htmlFor={`input_${inputId}`}>
        {label}
        <span className="label-info">{info !== null && <span>{info}</span>}</span>
      </label>

      <div className="input--radio__bgcolor" />
      {showPrice ? (
        <p className="input--radio__price">
          {getNumberWithCurrency(price_amount_float, currency_code)}
        </p>
      ) : (
        priceInfo && priceInfo.length > 1 && <p className="input--radio__price">{priceInfo}</p>
      )}
    </div>
  );
};

export default InputRadioCustom;
