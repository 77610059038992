import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { PaymentMethods } from "../../commercelayer/vo/paymentMethods";
import { ShippingMethods } from "../../commercelayer/vo/shippingMethods";
import { getClMethodInfoText } from "../scripts/info";
import { getLang } from "../../router/scripts/params";

interface Props {
  option: ShippingMethods | PaymentMethods | null;
}

const OptionShippingPaymentDisplay: React.FC<Props> = ({ option }) => {
  const [info, setInfo] = useState<string | null>("");
  const [reference, setReference] = useState("");
  const lang = getLang();

  useEffect(() => {
    if (!option) {
      return;
    }

    if (!option?.attributes?.reference) {
      return;
    }
    setReference(option.attributes.reference);
  }, [option]);

  useEffect(() => {
    setInfo(getClMethodInfoText(option));
  }, [option, lang]);

  return (
    <div className="text">
      {reference && <p className="text__title">{i18next.t(`CL_METHOD_${reference}`)}</p>}
      {info && <p className="text__text">{info}</p>}
    </div>
  );
};

export default OptionShippingPaymentDisplay;
