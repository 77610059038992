import React, { Dispatch, SetStateAction } from "react";
import i18next from "i18next";
import Address from "../../common/components/Address";
import { Addresses as AddressesVo } from "commercelayer/vo/addresses";
import Cart from "../../state/vo/Cart";
import Account from "../../state/vo/Account";
import { getBillingAddress, getShippingAddress } from "../scripts/addresses";
import { setShippingAddress } from "../actions/checkoutActions";
import InputRadioCustom from "./InputRadioCustom";
import { getAllAddresses } from "account/scripts/addresses";
import AddressFormAdd from "../../common/components/AddressFormAdd";
import { OrderType } from "../../common/constants/OrderType";
import { setConsignmentStockOrderShippingAddress } from "../../consignment-stock/actions/consignmentStockActions";

interface Props {
  cart: Cart;
  account: Account;
  showForm: boolean;
  setShowForm: Dispatch<SetStateAction<boolean>>;
}

const Addresses: React.FC<Props> = ({ cart, account, showForm, setShowForm }) => {
  const shippingAddress: AddressesVo | null = getShippingAddress(cart, account.addresses);
  const billingAddress: AddressesVo | null = getBillingAddress(cart, account.addresses);
  const accountAddresses = account.addresses;
  const allAddresses: AddressesVo[] = getAllAddresses(accountAddresses);

  const onChange = function (e) {
    const addressId = e.target.value;
    if (cart.attributes?.metadata.type === OrderType.CST_ORDER) {
      setConsignmentStockOrderShippingAddress(addressId);
    } else {
      setShippingAddress(addressId);
    }
  };

  const handleClick = () => {
    setShowForm(false);
  };

  return (
    <div className="grid-wrap grid-wrap--checkout">
      <div className="grid-1-2">
        <h2 className="col-title">{i18next.t("DELIVERY ADDRESS")}</h2>

        {showForm && (
          <AddressFormAdd onSubmit={() => setShowForm(false)} handleClick={handleClick} />
        )}

        {shippingAddress !== null && !showForm && (
          <>
            <Address address={shippingAddress} allowDelete={false} />
          </>
        )}
        {shippingAddress !== null && allAddresses.length > 1 && (
          <form className="address-selector">
            <fieldset className="form-fieldset">
              <legend className="visuallyhidden">{i18next.t("SHIPPING")}</legend>
              {allAddresses.map((address, index) => {
                let label = address.attributes.company;
                if (address.attributes.notes !== null) {
                  label += `, ${address.attributes.notes}`;
                }
                return (
                  <InputRadioCustom
                    key={address.id}
                    checked={shippingAddress.id === address.id}
                    inputId={address.id}
                    inputValue={address.id}
                    label={label}
                    info={`${address.attributes.line_1}, ${address.attributes.zip_code} ${address.attributes.city}`}
                    showPrice={false}
                    price_amount_float={0}
                    currency_code={cart.attributes?.currency_code}
                    onChange={onChange}
                  />
                );
              })}
            </fieldset>
          </form>
        )}
      </div>
      <div className="grid-1-2">
        <h2 className="col-title">{i18next.t("INVOICE ADDRESS")}</h2>
        {billingAddress !== null && <Address address={billingAddress} allowDelete={false} />}
      </div>
    </div>
  );
};

export default Addresses;
